<template>
  <div>
    <el-card class="box-card">
      <Search :isShow="false" @searchData="searchData"></Search>
      <br>
      <!--            <InfoDetail></InfoDetail>-->
      <el-tabs @tab-click="handleClick" v-model="activeName">
        <el-tab-pane :label="`可报名(${allcount.able_count})`" name="able"
                     v-if="allcount.able_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`我的报名(${allcount.mine_count})`" name="mine"
                     v-if="allcount.mine_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`已初选的(${allcount.employ_count})`" name="employ"
                     v-if="allcount.employ_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`已合作的(${allcount.confirm_count})`" name="confirm"
                     v-if="allcount.employ_count!==undefined"></el-tab-pane>
        <div class="default-table">
          <el-table :data="tableData" border
                    stripe
                    style="width: 100%">
            <el-table-column
                align="center"
                label="序号"
                type="index"
                width="60"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="创建日期"
                prop="created_at"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="项目"
                min-width="200"
                prop="name"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                <el-link @click="detailPage(row.id)" size="mini"
                         type="primary" v-if="userPermissions.indexOf('inquiry_apply_detail') !== -1">
                  <span>{{ row.name }}</span>
                </el-link>
                <span v-else>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="是否定向"
                prop="is_assign"
                width="100"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="意向达人"
                prop="intentions"
                show-overflow-tooltip
                width="100"

            >
              <template slot-scope="{row}">
        <span :key="i.id" v-for="i in row.intentions">
                           {{ i.nickname }}
                       </span></template>
            </el-table-column>
            <el-table-column
                align="center"
                label="品牌"
                prop="brand"
                show-overflow-tooltip
                width="100"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="产品"
                prop="product"
                show-overflow-tooltip
                width="100"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="所属集团"
                prop="group.display_name"
                show-overflow-tooltip
                width="100"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="是否寄回"
                prop="is_send_back"
            >

            </el-table-column>
            <el-table-column
                align="center"
                label="合作平台"
                prop="platforms"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ String(row.platforms) }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"

                label="是否平台下单"
                prop="is_platform_pay"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="合作形式"
                prop="coop_id"

            >
              <template slot-scope="{row}">
                {{ row.coop ? row.coop.display_name : '' }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="是否佣金、挂车"
                prop="is_cart"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="挂车、佣金说明"
                prop="cart_rate"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ row.cart_rate + '%' || '' }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="是否授权平台"
                prop="is_auth_price"
                show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
                label="期望档期"
                prop="expect_start"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ row.expect_start.slice(0, 10) }}至{{ row.except_end.slice(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="报名时间"
                prop="apply_start"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ row.apply_start.slice(0, 10) }}至{{ row.apply_end.slice(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="项目状态" prop="status"
                show-overflow-tooltip

                width="80"
            >
              <template slot-scope="{row}">
                {{ row.status == 1 ? '进行中' : row.status == 2 ? '已结束' : row.status == 3 ? '已作废' : '已完结' }}
              </template>
            </el-table-column>
            <el-table-column
                align="center" label="总报名"
                prop="details_count"
                show-overflow-tooltip
                v-if="activeName=='mine'"
                width="120"
            >
            </el-table-column>
            <el-table-column
                align="center" label="已录用"
                prop="employ_count"
                show-overflow-tooltip
                v-if="activeName=='employ'"
                width="120"
            >
            </el-table-column>
            <el-table-column
                align="center" label="我已报"
                prop="mine_count"
                show-overflow-tooltip
                v-if="activeName=='mine'||activeName=='employ'"
                width="120"
            >
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="{row}">
                <el-row>
                  <el-col :span="12">
                    <el-link @click="detailPage(row.id)" icon="el-icon-c-scale-to-original"
                             size="mini"
                             type="primary"
                             v-if="userPermissions.indexOf('inquiry_apply_detail') !== -1">
                      {{ activeName == 'able' ? '报名' : '查看' }}
                    </el-link>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="24" style="text-align: right">
              <Pagination :limit.sync="tablePagination.page_size"
                          :page.sync="tablePagination.current_page"
                          :total="tablePagination.total"
                          @pagination="infoData"/>
            </el-col>
          </el-row>
        </div>

      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Search from './components/search'
import InfoDetail from './InfoDetail'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Search, InfoDetail, PageHeaderLayout },
  data() {
    return {
      tableData: [],
      drawerVisible: false,
      rowId: 'add',
      activeName: 'mine',
      tablePagination: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      allcount: {},
      searchDatas: {}

    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  watch: {
    drawerVisible: {
      handler(val) {
        if (!val) {
          this.infoData()
        }
      }
    }
  },
  methods: {
    handleClick() {
      this.infoData()
    },
    searchData(val) {
      this.searchDatas = val
      let cond = {}
      let name = ['name', 'status', 'brand', 'created_start', 'created_end', 'expect_start', 'expect_end', 'is_apply']
      name.forEach((type) => {
        if (val[type] != '') {
          cond[type] = val[type]
        }
      })
      if (val.intentions?.length != 0) {
        cond['intentions'] = val.intentions
      }
      if (val.platforms?.length != 0) {
        cond['platforms'] = val.platforms
      }
      this.searchDatas = cond
      this.infoData()
    },

    async infoData() {
      let searchCondition = this.searchDatas
      Object.assign(searchCondition, {
        page_size: this.tablePagination.page_size || 15,
        current_page: this.tablePagination.current_page || 1
      }, { type: this.activeName })
      let { list, page_info } = await this.$api.listApplyInquiry(searchCondition)
      let data = await this.$api.applyCount()

      // applyCount
      this.$nextTick(() => {
        this.allcount = data
        let convertData = ['is_assign', 'is_platform_pay', 'is_send_back', 'is_cart', 'is_auth_price']
        list.forEach((item) => {
          convertData.forEach((i) => {
            if (i === 'is_assign' || i === 'is_send_back') {
              item[i] = item[i] == 1 ? '是' : '否'
            } else item[i] = item[i] == 1 ? '否' : '是'
          })
        })
        this.tableData = list
        this.tablePagination = page_info

      })
    },
    async handleVoid(rowId) {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      let statusData = {
        id: rowId,
        status: 3
      }
      let id = await this.$api.switchInquiryStatus(statusData)
      if (id) {
        this.$notify.success('作废成功')
        this.infoData()
      }
    },
    detailPage(id) {
      this.$router.push({
        name: 'applyDetail',
        params: { id: id }
      })
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    }
  },
  mounted() {
    this.infoData()
  }
}
</script>

<style scoped>

</style>
